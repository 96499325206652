import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA10fdJ0Lzb9-6ZWH9b7-6RX7A9dePXQss",
  authDomain: "lifedriver-new.firebaseapp.com",
  projectId: "lifedriver-new",
  storageBucket: "lifedriver-new.appspot.com",
  messagingSenderId: "1077102367536",
  appId: "1:1077102367536:web:1010b978219f312edceef5"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();