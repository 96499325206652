import { Box, Breadcrumbs, Button, LinearProgress, Modal, Tab, Tabs, Typography } from "@mui/material";
import { collection, deleteDoc, doc, getDocs, orderBy, query, updateDoc } from "firebase/firestore";
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import { db } from "../../firebase-config";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import avatar from "../../assets/ava-driver.png";
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';

const DepositList = () => {
    const usersCollectionRef = collection(db, "damages");
    const [drivers, setDrivers] = useState([]);

    useEffect(() => {
        const getUsers = async () => {
            const data = await getDocs(query(usersCollectionRef));
            setDrivers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getUsers();
    }, []);

    const deleteDriver = async (id) => {
        const userDoc = doc(db, "drivers", id);
        await deleteDoc(userDoc);
        if (deleteDriver) {
            handleOpen();
        } else {
            alert("ошибка загрузки");
        }
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const date = new Date();

    const getAllDocuments = async () => {
        const autoCollectionRef = collection(db, "auto");
        const querySnapshot = await getDocs(autoCollectionRef);
        const documents = [];

        querySnapshot.forEach((doc) => {
            documents.push({ id: doc.id, data: doc.data() });
        });
        return documents;
    };

    const fetchTransactions = async () => {
        const q = query(collection(db, "damages"));
        const querySnapshot = await getDocs(q);
        let totals = {};
        querySnapshot.forEach((doc) => {
            const transactions = doc.data().transactions || [];
            const totalAmount = transactions.reduce((sum, transaction) => sum + parseFloat(transaction.amountNew || 0), 0);
            totals[doc.id] = totalAmount;
        });
        return totals;
    };

    const [totalAmounts, setTotalAmounts] = useState({});

    useEffect(() => {
        const loadData = async () => {
            const totals = await fetchTransactions();
            setTotalAmounts(totals);
        };
        loadData();
    }, []);

    function formatNumber(num) {
        const strNum = num.toString();
        if (strNum.length <= 3) {
            return strNum;
        } else if (strNum.length === 4) {
            return strNum.slice(0, 1) + ' ' + strNum.slice(1);
        } else if (strNum.length === 5) {
            return strNum.slice(0, 2) + ' ' + strNum.slice(2);
        } else if (strNum.length >= 6) {
            return strNum.slice(0, 3) + ' ' + strNum.slice(3);
        }
    }

    const sortedDrivers = drivers.sort((a, b) => {
        const aAmount = formatNumber(a.amount);
        const bAmount = formatNumber(b.amount);
        const aTotal = formatNumber(totalAmounts[a.id] || 0);
        const bTotal = formatNumber(totalAmounts[b.id] || 0);
        return (aAmount === aTotal) - (bAmount === bTotal);
    });

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [deposits, setDeposits] = useState([]);

    useEffect(() => {
        const fetchDeposits = async () => {
            const querySnapshot = await getDocs(collection(db, "deposits"));
            const loadedDeposits = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                lastTransaction: doc.data().transactions[doc.data().transactions.length - 1] || { amount: 0, comment: '' }
            }));
            setDeposits(loadedDeposits);
        };
        fetchDeposits();
    }, []);

    // Функция для вычисления общей суммы всех транзакций для одного депозита
    const calculateTotalAmount = (transactions) => {
        return transactions.reduce((total, transaction) => total + transaction.amount, 0);
    };

    // Функция для вычисления общей суммы всех депозитов
    const calculateTotalAllDeposits = () => {
        return deposits.reduce((total, deposit) => total + calculateTotalAmount(deposit.transactions), 0);
    };

    return (
        <div className="grid">
            <Header />
            <div class='content'>
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <a className="breadcrumbs-first" href="/" underline="hover" color="inherit">
                            Дашборд
                        </a>
                        <Typography color="text.primary"><b>Залоги</b></Typography>
                    </Breadcrumbs>
                </div>
                <div>
                    <p className="header-mt">Залоги</p>
                </div>
                <div className="box-info-grid">
                    <div className="box-info all_deposit_widjet">
                        <CurrencyRubleIcon className="box-info-icon" />
                        <div>
                            <p className="p-info">Всего залогов на:</p>
                            <p className="p-b-info">{formatNumber(calculateTotalAllDeposits())} ₽</p>
                        </div>
                    </div>
                </div>
                <div className="info-active-block-table">
                    <Button
                        variant="outlined"
                        size="small"
                        className="btn-add-to-table"
                        component={Link}
                        to={'/add_deposit'}
                    >
                        Добавить
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className="btn-settings-table"
                    >
                        <SettingsIcon className="icon-setting" />
                    </Button>
                    <div className="drivers-block-btn"></div>
                </div>
                <table id="myTable">
                    <thead>
                        <tr>
                            <th>Ф.И.О. водителя</th>
                            <th className="center-table">Сумма залога</th>
                            <th className="center-table">Сколько нужно залога</th>
                            <th className="center-table">Действия</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deposits.map((deposit) => (
                            <tr key={deposit.id}>
                                <td>{deposit.driver}</td>
                                <td className="center-table">{calculateTotalAmount(deposit.transactions)}</td>
                                <td className="center-table">{formatNumber(deposit.totalDeposit)}</td>
                                <td className="end-actions-table">
                                    <Link to={`/deposit_view/${deposit.id}`}>
                                        <Button variant="none" size="small" className="btn-icon-table">
                                            <VisibilityIcon className="icon-table" />
                                        </Button>
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div >
    )
}

export default DepositList;

