import React, { useState, useEffect } from "react";
import "../../App.css";
import { db } from "../../firebase-config";
import {
    collection,
    getDocs,
    addDoc,
} from "firebase/firestore";
import { Button, Modal, Select, Typography, MenuItem, TextareaAutosize, Breadcrumbs, Link, Box, Grid } from "@mui/material";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import NoCrashIcon from '@mui/icons-material/NoCrash';
import MinorCrashIcon from '@mui/icons-material/MinorCrash';


const AddDamage = () => {
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [patronymic, setPatronymic] = useState("");

    const [driver, setDriver] = useState("");
    const [guilt, setGuilt] = useState("");
    const [typeDamage, setTypeDamage] = useState("");
    const [comment, setComment] = useState("");
    const [amount, setAmount] = useState("");

    const handleChangeGuilt = (event) => {
        setGuilt(event.target.value);
    };
    const handleChangeTypeDamage = (event) => {
        setTypeDamage(event.target.value);
    };

    const [auto, setAuto] = useState([]); // Массив объектов автомобилей
    const [selectedAutoId, setSelectedAutoId] = useState(""); // Выбранный идентификатор автомобиля
    useEffect(() => {
        const getAuto = async () => {
            const autoCollectionRef = collection(db, "auto");
            const querySnapshot = await getDocs(autoCollectionRef);
            const autoData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setAuto(autoData);
        };
        getAuto();
    }, []);
    const handleChangeCar = (event) => {
        setSelectedAutoId(event.target.value);
    };

    const damageCollectionRef = collection(db, "damages");
    const createDamages = async () => {
        await addDoc(damageCollectionRef, {
            typeDamage: typeDamage,
            guilt: guilt,
            auto: selectedAutoId,
            driver: driver,
            amount: amount,
            comment: comment,
        });
        if (createDamages) {
            handleOpen();
        } else {
            alert("ошибка загрузки");
        }
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div className="grid">
            <Header />
            <div class='content'>
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            Дашборд
                        </Link>
                        <Link underline="hover" color="inherit" href="/damage">
                            ДТП/Повреждение
                        </Link>
                        <Typography color="text.primary"><b>Новое ДТП-Повреждение</b></Typography>
                    </Breadcrumbs>
                </div>
                <p className="header-mt">Добавление ДТП / Повреждение</p>
                <div className="info-active-block-table">
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={createDamages}
                        className="btn-save-to-table"
                    >
                        Сохранить
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        href="/auto"
                        className="btn-back-to-table"
                    >
                        Отменить
                    </Button>
                    <div className="drivers-block-btn"></div>
                </div>
                <div className="box-block-edit-params">
                    <div className="box-create-form">
                        <Grid container spacing={2} className="grid-form-add">
                            <Grid item xs={2}>
                                <p className="text-form">Тип повреждения:</p>
                            </Grid>
                            <Grid item xs={3}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={typeDamage}
                                    onChange={handleChangeTypeDamage}
                                    className="select-add-techinsp"
                                >
                                    <MenuItem value={'ДТП'}>ДТП</MenuItem>
                                    <MenuItem value={'Повреждение'}>Повреждение</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="grid-form-add">
                            <Grid item xs={2}>
                                <p className="text-form">Виновность:</p>
                            </Grid>
                            <Grid item xs={3}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={guilt}
                                    onChange={handleChangeGuilt}
                                    className="select-add-techinsp"
                                >
                                    <MenuItem value={'Виноват'}>Виноват</MenuItem>
                                    <MenuItem value={'Не виноват'}>Не виноват</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="grid-form-add">
                            <Grid item xs={2}>
                                <p className="text-form">Водитель:</p>
                            </Grid>
                            <Grid item xs={3}>
                                <input
                                    className="input-damage"
                                    type="text"
                                    name="insuranceCompany"
                                    Value={driver}
                                    onChange={(event) => {
                                        setDriver(event.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="grid-form-add">
                            <Grid item xs={2}>
                                <p className="text-form">Автомобиль:</p>
                            </Grid>
                            <Grid item xs={3}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    Value={selectedAutoId}
                                    onChange={handleChangeCar}
                                    helperText="Some important text"
                                    className="select-add-techinsp"
                                >
                                    {auto.map((item) => (
                                        <MenuItem value={item.gos}>{item.gos}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} className="grid-form-add">
                            <Grid item xs={2}>
                                <p className="text-form">Общая сумма ущерба:</p>
                            </Grid>
                            <Grid item xs={2}>
                                <input
                                    className="input-damage"
                                    type="number"
                                    name="insuranceCompany"
                                    Value={amount}
                                    onChange={(event) => {
                                        setAmount(event.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <p className="text-form">Комментарий повреждений:</p>
                        <Grid container spacing={2} className="grid-form-add">
                            <Grid item xs={5}>
                                <TextareaAutosize
                                    id="outlined-basic"
                                    className="textArea_bodyText_tech"
                                    label="Имя"
                                    variant="outlined"
                                    type="textarea"
                                    size="small"
                                    sx={{ width: 1 }}
                                    Value={comment}
                                    onChange={(event) => {
                                        setComment(event.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style} className="box-modal-succes">
                        <Typography className="modal-grid-succes" id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                            <MinorCrashIcon className="modal-grid-succes-icon" />
                            <p className="modal-grid-succes-text">Водитель добавлен!</p>
                        </Typography>
                        <a href="/auto" className="modal-grid-succes-text-a">Вернуться в раздел Автомобили</a>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}

export default AddDamage;
