import React, { useState, useEffect } from "react";
import "../../App.css";
import { db } from "../../firebase-config";
import { updateDoc, doc, getDoc, setDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { Button, Modal, Select, Typography, MenuItem, Breadcrumbs, Link, Box } from "@mui/material";
import Header from "../../Header";
import { useParams } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';

const DamageView = () => {
    const { id } = useParams();
    const [data, setData] = useState({
        typeDamage: "",
        guilt: "",
        auto: "",
        driver: "",
        amount: "",
        comment: "",
        bank: "",
    });

    const { typeDamage, guilt, auto, driver, amount, comment, bank } = data;

    const [isEditing, setIsEditing] = useState(false); // New state for editing
    const [editValues, setEditValues] = useState({
        typeDamage: "",
        guilt: "",
        auto: "",
        driver: "",
        amount: "",
        comment: "",
        bank: "",
    });

    useEffect(() => {
        id && getsingleAuto();
    }, [id]);

    const getsingleAuto = async () => {
        const docRef = doc(db, "damages", id);
        const snapshot = await getDoc(docRef);
        if (snapshot.exists()) {
            const data = snapshot.data();
            setData(data);
            setEditValues(data); // Initialize edit values
        }
    };

    const handleInputChange = (event) => {
        setEditValues({ ...editValues, [event.target.name]: event.target.value });
    };

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openAdd, setOpenAdd] = useState(false);
    const handleOpenAdd = () => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const [dateOfMoneyReceived, setDateOfMoneyReceived] = useState('');
    const [commentNew, setCommentNew] = useState('');
    const [amountNew, setAmountNew] = useState('');

    const handleSaveTransaction = async () => {
        const docRef = doc(db, "damages", id);
        try {
            await setDoc(docRef, {
                transactions: arrayUnion({
                    dateOfMoneyReceived,
                    commentNew,
                    amountNew,
                    bank: bankNew,
                })
            }, { merge: true });
            window.location.reload();
            alert("Деньги в счет повреждений - Добавлены!");
        } catch (error) {
            alert("Ошибка: " + error.message);
            console.error("Error updating document: ", error);
        }
    };

    const handleSaveEdit = async () => {
        const docRef = doc(db, "damages", id);
        const updatedData = { ...editValues };

        // Remove undefined fields
        Object.keys(updatedData).forEach(key => {
            if (updatedData[key] === undefined) {
                delete updatedData[key];
            }
        });

        try {
            await updateDoc(docRef, updatedData);
            setData(editValues); // Update the data with the edited values
            setIsEditing(false);
            alert("Данные успешно обновлены!");
        } catch (error) {
            alert("Ошибка при сохранении изменений: " + error.message);
            console.error("Error updating document: ", error);
        }
    };

    const [transactions, setTransactions] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0); // Для хранения суммы всех транзакций

    useEffect(() => {
        const fetchData = async () => {
            const docRef = doc(db, "damages", id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const data = docSnap.data();
                if (data.transactions) {
                    setTransactions(data.transactions);
                    // Расчёт общей суммы
                    const total = data.transactions.reduce((sum, current) => {
                        return sum + parseFloat(current.amountNew || '0'); // Добавляем 0 для недопустимых значений
                    }, 0);
                    setTotalAmount(total); // Обновляем состояние с общей суммой
                }
            } else {
                console.log("No such document!");
            }
        };
        fetchData();
    }, [id]);

    const ostatok = amount - totalAmount;

    const [bankNew, setBankNew] = useState('');
    const changeBank = (event) => {
        setBankNew(event.target.value);
    };

    const handleRemove = async (transaction) => {
        const docRef = doc(db, "damages", id);
        try {
            await updateDoc(docRef, {
                transactions: arrayRemove(transaction)
            });
            alert("Транзакция удалена");
            window.location.reload();
        } catch (error) {
            alert("Ошибка при удалении: " + error.message);
            console.error("Error removing transaction: ", error);
        }
    };

    return (
        <div className="grid">
            <Header />
            <div className='content'>
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/">
                            Дашборд
                        </Link>
                        <Link underline="hover" color="inherit" href="/damage">
                            ДТП/Повреждение
                        </Link>
                        <Typography color="text.primary"><b>Просмотр</b></Typography>
                    </Breadcrumbs>
                </div>
                <p className="header-mt"> {auto} - {typeDamage} </p>
                <div className="box-damages-view">
                    <div className="box-create-form">
                        <div className="block-grid-damages-view">
                            <div>
                                <p className="text-form">Тип повреждения:</p>
                            </div>
                            <div>
                                <input
                                    className={`input-damage ${isEditing ? 'editable' : ''}`}
                                    type="text"
                                    name="typeDamage"
                                    value={isEditing ? editValues.typeDamage : typeDamage}
                                    onChange={handleInputChange}
                                    readOnly={!isEditing}
                                />
                            </div>
                        </div>
                        <div className="block-grid-damages-view">
                            <div>
                                <p className="text-form">Виновность:</p>
                            </div>
                            <div>
                                <input
                                    className={`input-damage ${isEditing ? 'editable' : ''}`}
                                    type="text"
                                    name="guilt"
                                    value={isEditing ? editValues.guilt : guilt}
                                    onChange={handleInputChange}
                                    readOnly={!isEditing}
                                />
                            </div>
                        </div>
                        <div className="block-grid-damages-view">
                            <div>
                                <p className="text-form">Водитель:</p>
                            </div>
                            <div>
                                <input
                                    className={`input-damage ${isEditing ? 'editable' : ''}`}
                                    type="text"
                                    name="driver"
                                    value={isEditing ? editValues.driver : driver}
                                    onChange={handleInputChange}
                                    readOnly={!isEditing}
                                />
                            </div>
                        </div>
                        <div className="block-grid-damages-view">
                            <div>
                                <p className="text-form">Автомобиль:</p>
                            </div>
                            <div>
                                <input
                                    className={`input-damage ${isEditing ? 'editable' : ''}`}
                                    type="text"
                                    name="auto"
                                    value={isEditing ? editValues.auto : auto}
                                    onChange={handleInputChange}
                                    readOnly={!isEditing}
                                />
                            </div>
                        </div>
                        <div className="block-grid-damages-view">
                            <div item xs={5}>
                                <p className="text-form">Общая сумма ущерба:</p>
                            </div>
                            <div item xs={5}>
                                <input
                                    className={`input-damage ${isEditing ? 'editable' : ''}`}
                                    type="text"
                                    name="amount"
                                    value={isEditing ? editValues.amount : amount}
                                    onChange={handleInputChange}
                                    readOnly={!isEditing}
                                />
                            </div>
                        </div>
                        <div className="block-grid-damages-view">
                            <div>
                                <p className="text-form">Сколько выплатил:</p>
                            </div>
                            <div>
                                <input
                                    className="input-damage"
                                    type="text"
                                    name="totalAmount"
                                    value={totalAmount}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="block-grid-damages-view">
                            <div>
                                <p className="text-form">Остаток долга:</p>
                            </div>
                            <div>
                                <input
                                    className="input-damage"
                                    type="text"
                                    name="ostatok"
                                    value={ostatok}
                                    readOnly
                                />
                            </div>
                        </div>
                    </div>
                    <div className="box-create-form">
                        <div container spacing={2} className="grid-form-add">
                            <div item xs={5}>
                                <p className="text-form">Комментарий повреждений:</p>
                            </div>
                            <div item xs={5}>
                                <input
                                    className={`textArea_bodyText_tech ${isEditing ? 'editable' : ''}`}
                                    type="textarea"
                                    name="comment"
                                    value={isEditing ? editValues.comment : comment}
                                    onChange={handleInputChange}
                                    readOnly={!isEditing}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="block-mg">
                        {isEditing ? (
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={handleSaveEdit}
                                className="btn-save"
                            >
                                Сохранить
                            </Button>
                        ) : (
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={() => setIsEditing(true)}
                                className="btn-edit"
                            >
                                Редактировать
                            </Button>
                        )}
                    </div>
                </div>
                <div className="block-mg">
                    <Button
                        variant="outlined"
                        size="small"
                        className="btn-add-to-table"
                        onClick={handleOpenAdd}
                    >
                        Добавить
                    </Button>
                </div>
                <div>
                    <table id="myTable">
                        <thead>
                            <tr>
                                <th>Дата</th>
                                <th>Сумма</th>
                                <th>Банк зачисления</th>
                                <th>Откуда деньги</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.map((transaction, index) => (
                                <tr key={index}>
                                    <td>{transaction.dateOfMoneyReceived}</td>
                                    <td>{transaction.amountNew}</td>
                                    <td>{transaction.bank}</td>
                                    <td>{transaction.commentNew}</td>
                                    <td><ClearIcon className="btn-transactions-delete" onClick={() => handleRemove(transaction)} /></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style} className="box-modal-succes">
                        <Typography className="modal-grid-succes" id="keep-mounted-modal-description" sx={{ mt: 2 }}>
                            <CheckCircleIcon className="modal-grid-succes-icon" />
                            <p className="modal-grid-succes-text">Данные изменены</p>
                            <p className="modal-grid-сucces-text">все отлично!</p>
                        </Typography>
                        <a href="/auto" className="modal-grid-succes-text-a">Вернуться в раздел Автомобили</a>
                    </Box>
                </Modal>
                <Modal
                    keepMounted
                    open={openAdd}
                    onClose={handleCloseAdd}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
                >
                    <Box sx={style} className="box-modal-succes">
                        <div className="">
                            <div container spacing={2} className="grid-form-add">
                                <div item xs={5}>
                                    <p className="label-input-add-damage">Сумма:</p>
                                </div>
                                <div item xs={5}>
                                    <input
                                        className="input-add-damage"
                                        type="number"
                                        name="insuranceCompany"
                                        value={amountNew}
                                        onChange={(e) => setAmountNew(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="grid-form-add">
                                <div>
                                    <p className="label-input-add-damage">Дата и время платежа:</p>
                                </div>
                                <div >
                                    <input
                                        className="input-add-damage"
                                        type="text"
                                        name="insuranceCompany"
                                        value={dateOfMoneyReceived}
                                        onChange={(e) => setDateOfMoneyReceived(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="grid-form-add">
                                <div>
                                    <p className="label-input-add-damage">Банк получателя:</p>
                                </div>
                                <div >
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        className="select-add-techinsp"
                                        value={bankNew}
                                        onChange={changeBank}
                                    >
                                        <MenuItem value={'Алина Ф. (Сбербанк)'} className="select-bank-sber">Алина Ф. (Сбербанк)</MenuItem>
                                        <MenuItem value={'Евгений Ф. (Сбербанк)'} className="select-bank-sber">Евгений Ф. (Сбербанк)</MenuItem>
                                        <MenuItem value={'Юрий С. (Сбербанк)'} className="select-bank-sber">Юрий С. (Сбербанк)</MenuItem>
                                        <MenuItem value={'Евгений Ф. (Тинькофф)'} className="select-bank-tink"> Евгений Ф. (Тинькофф)</MenuItem>
                                        <MenuItem value={'Юрий С. (Тинькофф)'} className="select-bank-tink"> Юрий С. (Тинькофф)</MenuItem>
                                        <MenuItem value={'Юрий С. (Альфа-Банк)'} className="select-bank-alfa"> Юрий С. (Альфа-Банк)</MenuItem>
                                        <MenuItem value={'Другое'}> Другое</MenuItem>
                                    </Select>
                                </div>
                            </div>

                            <div container spacing={2} className="grid-form-add">
                                <div item xs={5}>
                                    <p className="label-input-add-damage">Комментарий по внесению д/с:</p>
                                </div>
                                <div item xs={5}>
                                    <input
                                        className="input-add-damage"
                                        type="text"
                                        name="insuranceCompany"
                                        value={commentNew}
                                        onChange={(e) => setCommentNew(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={handleSaveTransaction}
                            className="btn-add-money"
                        >
                            Сохранить
                        </Button>
                    </Box>
                </Modal>
            </div>
        </div>
    );
}

export default DamageView;
